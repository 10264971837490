import { Typography } from '@mui/material';
import { FormWrapper } from '../../../Common/FormWrapper';
import { useNavigate } from 'react-router-dom';

export const ProlongationHelpFinal = () => {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(`/`);
  };
  return (
    <FormWrapper onSubmit={onSubmit} title="" currentStep={2} isLastStep>
      <Typography>
        Váš požadavek byl úspěšně odeslán.
        <br />
        <br />
        Na email jsme Vám zaslali potvrzení o přijetí požadavku. V nejbližší
        době Vás budeme v dané záležitosti kontaktovat emailem nebo telefonicky.
      </Typography>
    </FormWrapper>
  );
};
