import { FC } from 'react';
import { FormWrapper } from '../../../Common/FormWrapper';
import {
  Box,
  Table,
  TableContainer,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import QrCode from '../../../Sections/QrCode';
import { LoanDto, ProlongationPreviewDto } from '../../../../types/api';
import { formatDate, formatMoney } from '../../../../utils/format';

interface IProlongationRequestInformationProps {
  next: () => void;
  loan: LoanDto;
  prolongationPreview: ProlongationPreviewDto | null;
}

export const ProlongationRequestInformation: FC<
  IProlongationRequestInformationProps
> = ({ next, loan, prolongationPreview }) => {
  const onSubmit = () => {
    next && next();
  };

  const {
    externalLoanRef,
    prolongationDueDate,
    prolongationFeeTotal,
    paymentQrCode,
  } = prolongationPreview ?? {};
  const { paymentInstruction } = loan;

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={2}
      title="Úhrada poplatku za odklad"
    >
      <Typography fontWeight={400}>
        Pro aktivaci odkladu termínu splatnosti Financování je nutné{' '}
        <Typography component="span" color="primary" fontWeight="bold">
          uhradit níže uvedený poplatek.
        </Typography>{' '}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          gap: 2,
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: '8px',
          margin: '1rem auto',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack column on small screens
        }}
      >
        {/* Left Side Table */}
        <Box sx={{ flex: 2 }}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="payment details">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Číslo bankovního účtu:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ wordBreak: 'break-word' }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {paymentInstruction?.bban ?? paymentInstruction?.iban}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Variabilní symbol:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {externalLoanRef ?? ''}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      K úhradě:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {formatMoney(
                        prolongationFeeTotal,
                        paymentInstruction?.currency
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      Datum splatnosti:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {formatDate(prolongationDueDate)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Right Side QR Code */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Typography variant="body1" fontWeight="bold" gutterBottom>
            Zaplatit pomocí QR kódu
          </Typography> */}
          <QrCode
            currency={paymentInstruction?.currency ?? ''}
            qrCode={paymentQrCode ?? ''}
          />
        </Box>
      </Box>
      <Typography fontSize="14px">
        Pokud nebude aktivační poplatek uhrazen, dojde ke zrušení Vaší žádosti.
      </Typography>
    </FormWrapper>
  );
};
