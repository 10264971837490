import React from 'react';
import { FormWrapper } from '../../../Common/FormWrapper';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

export const ProlongationRequestFinal = () => {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('/');
  };
  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={3}
      isLastStep
      title=""
      buttonText="Pokračovat do aplikace"
    >
      <Typography>
        Vaši žádost o odklad splatnosti jsme úspěšně přijali a předali ke
        zpracování.
      </Typography>
      <br />
      <br />
      <Typography>
        Po přijetí platby na náš účet Vám zašleme{' '}
        <Typography fontWeight="bold" component="span">
          Dohodu o splátkovém kalendáři
        </Typography>{' '}
        k podpisu na e-mail.
      </Typography>
    </FormWrapper>
  );
};
