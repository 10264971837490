import { FC, useState } from 'react';
import { InputLabel, TextField, Typography } from '@mui/material';
import { FormWrapper } from '../../../Common/FormWrapper';
import { useParams } from 'react-router-dom';
import {
  ContactFormDto,
  ContactFormDtoContactFormContext,
} from '../../../../types/api';
import axios from '../../../../utils/axios';
import { useAppDispatch } from '../../../../selectors/selectors';
import { parseApiError } from '../../../../utils/utils';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';
import { useLoading } from '../../../../context/loading-context';
import Loading from '../../../UI/Loading';

interface IProlongationHelpFormProps {
  next: () => void;
}

export const ProlongationHelpForm: FC<IProlongationHelpFormProps> = ({
  next,
}) => {
  const [formInput, setFormInput] = useState('');
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();
  const { loading, setLoading } = useLoading();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const submitData: ContactFormDto = {
        message: formInput,
        contactFormContext: ContactFormDtoContactFormContext.PROLONGATION,
      };

      await axios.post(`customers/${customerId}/contact-form`, submitData);

      next();
    } catch (error) {
      const { message } = parseApiError(error);
      dispatch(addSnackbar({ message, variant: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput(event.target.value);
  };

  return (
    <FormWrapper onSubmit={onSubmit} title="Kontaktní formulář" currentStep={1}>
      <InputLabel sx={{ marginBottom: '1rem' }}>
        <Typography variant="h6">Popis Vašeho požadavku</Typography>
      </InputLabel>
      <TextField
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={formInput}
        onChange={handleInputChange}
        placeholder="Napište nám, s čím Vám můžeme pomoci"
        required
      />
      {loading && <Loading isLoading={loading} />}
    </FormWrapper>
  );
};
