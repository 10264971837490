import { FC, useState } from 'react';
import { FormWrapper } from '../../../Common/FormWrapper';
import {
  CreateLoanProlongationDto,
  LoanDto,
  LoanProlongationOrigin,
  ProlongationPreviewDto,
} from '../../../../types/api';
import { Box, TextField, Typography } from '@mui/material';
import { formatDate, formatMoney } from '../../../../utils/format';
import { useLoading } from '../../../../context/loading-context';
import axios from '../../../../utils/axios';
import { parseApiError } from '../../../../utils/utils';
import { useAppDispatch } from '../../../../selectors/selectors';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';
import Loading from '../../../UI/Loading';

interface IProlongationRequestFormProps {
  loan: LoanDto;
  prolongationPreview: ProlongationPreviewDto | null;
  next: () => void;
}

export const ProlongationRequestForm: FC<IProlongationRequestFormProps> = ({
  loan,
  prolongationPreview,
  next,
}) => {
  const [formInput, setFormInput] = useState('');
  const { loading, setLoading } = useLoading();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const submitData: CreateLoanProlongationDto = {
        loanId: loan.id ?? '',
        prolongationReason: formInput,
        internalNote: '',
        origin: LoanProlongationOrigin.MANUAL,
      };

      await axios.post(`loans/${loan.id}/prolongations`, submitData);

      next();
    } catch (error) {
      const { message } = parseApiError(error);
      dispatch(addSnackbar({ message, variant: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput(event.target.value);
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={1}
      title="Žádost o odklad termínu splatnosti"
    >
      <Typography variant="h6" fontWeight="700">
        Podmínky realizace odkladu splatnosti:
      </Typography>
      <ul>
        <li>
          <Typography>
            Úhrada poplatku za odklad ve výši{' '}
            <Typography
              component="span"
              color="primary"
              sx={{ fontWeight: '700' }}
            >
              {formatMoney(
                prolongationPreview?.prolongationFeeTotal,
                loan?.currency
              )}
            </Typography>{' '}
            úhradu provedete v dalším kroku.
          </Typography>
        </li>
        <br />
        <li>
          <Typography>
            Uzavření dodatku k dohodě o financování, který obdržíte na email po
            přípsání poplatku za odklad na náš účet
          </Typography>
        </li>
      </ul>
      <Typography variant="h6" fontWeight="700">
        Po splnění podmínek bude splatnost financování odložena o jeden měsíc
        podle nového splátkového kalendáře:
      </Typography>
      <Box
        sx={{
          maxHeight: '8.5rem',
          overflowY: 'auto',
          padding: '0 1rem',
          marginTop: '1rem',
        }}
      >
        {prolongationPreview?.installments?.map((installment, index) => {
          const { amount, dueDate, paidDate } = installment;

          let content;
          if (paidDate) {
            content = (
              <>
                {formatMoney(amount, loan.currency)} se splatnosti do{' '}
                {formatDate(dueDate)} a uhrazeno dne {formatDate(paidDate)}
              </>
            );
          } else if (amount === prolongationPreview.prolongationFeeTotal) {
            content = (
              <>
                <Typography component="span" color="primary" fontWeight="bold">
                  {formatMoney(amount, loan.currency)}
                </Typography>{' '}
                poplatek za odklad{' '}
                <Typography
                  component="span"
                  color="secondary"
                  fontWeight="bold"
                >
                  neuhrazeno
                </Typography>
              </>
            );
          } else {
            content = (
              <>
                <Typography component="span" color="primary" fontWeight="bold">
                  {formatMoney(amount, loan.currency)}
                </Typography>{' '}
                se splatnosti do{' '}
                <Typography
                  component="span"
                  color="secondary"
                  fontWeight="bold"
                >
                  {formatDate(dueDate)}
                </Typography>
              </>
            );
          }

          return (
            <Typography
              key={index}
              sx={{
                marginBottom: '1rem',
                '&:last-child': { marginBottom: 0 },
              }}
            >
              <Box
                component="span"
                sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}
              >
                {index + 1}.
              </Box>
              {content}
            </Typography>
          );
        })}
      </Box>

      <Typography
        variant="h5"
        fontWeight="700"
        sx={{ margin: '2rem 0 1rem 0' }}
      >
        Důvod odkladu termínu splatnosti
      </Typography>
      <TextField
        multiline
        rows={2}
        fullWidth
        variant="outlined"
        value={formInput}
        onChange={handleInputChange}
        placeholder="Prosím uveďte důvod vaší žádosti o odklad."
        required
      />
      {loading && <Loading isLoading={loading} />}
    </FormWrapper>
  );
};
